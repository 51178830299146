import React from 'react';
import PropTypes from 'prop-types';

import PageLayout from '../layouts/PageLayout';
import SubpageTopCover from '../components/SubpageTopCover';
import Projects from '../components/PortfolioPage/Projects';
import Platforms from '../components/PortfolioPage/Platforms';
import ContactForm from '../components/ContactForm/';
import ValuedClients from '../components/HomePage/ValuedClients';
import { usePortfolioPicture } from '../hooks/use-portfolio-picture';
import { portfolio } from '../seoConfig';

const PortfolioPage = ({ location }) => {
	const img = usePortfolioPicture();
	return (
		<PageLayout
			location={location}
			seoConfig={portfolio}
			altText="pixelteh team at conference table"
		>
			<SubpageTopCover backgroundImg={img}>
				Creating Unforgettable Digital
				<br />
				Experiences for Our Clients
			</SubpageTopCover>
			<Projects />
			<ContactForm label="Portfolio Form" />
			<Platforms />
			<ValuedClients />
		</PageLayout>
	);
};

PortfolioPage.propTypes = {
	location: PropTypes.object,
};

export default PortfolioPage;
