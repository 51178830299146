import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media, colors, fonts } from '../../../globalStyles';
import H3 from '../../_common/H3';
import P from '../../_common/P';
import ProjectButton from './ProjectButton';
import AnimatedOnScroll from '../../_common/AnimatedOnScroll';
import linkArrow from '../../../assets/img/icon-arrow-more.png';

const StyledProject = styled.div`
	display: flex;
	justify-content: space-between;
	margin-right: -15px;
	margin-left: -15px;
	background-color: ${({ mainBgColor }) => mainBgColor};
	overflow: hidden;
	&:last-of-type {
		margin-bottom: -87px;
		${media.maxSm`margin-bottom: -30px;`}
	}
	.animated:first-of-type {
		width: 64.5%;
		${media.laptopS`width: 100%;`}
	}
	.animated:last-of-type {
		width: 35.5%;
		${media.maxXl`width: 46.5%;`}
		${media.laptopS`display: none;`};
	}
	.left-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    padding-top: 110px;
    ${media.maxXl`padding-top: 45px;`}
    ${media.laptopS`
    	padding-top: 20vw;
    	padding-bottom: 10vw;
    	background-image: linear-gradient(${({ linearGradient }) => linearGradient})
    `}
    & > figure {
    	${media.laptopS`display: none;`}
    	& + ${H3} {
    		display: ${({ noTitle }) => (noTitle ? 'none' : 'block')};
    		${media.laptopS`display: none;`}
    	}
    	}
    	${H3} {
    		display: inline-block;
    		color: ${colors.white};
				font-family: ${fonts.openSans};
				font-size: 64px;
				font-weight: 600;
				margin-top: 50px;
				${media.maxXl`margin-top: 10px`}
				${media.laptopS`
					padding-bottom: 15px;
					font-family: ${fonts.loveloBlack};
					font-size: 30px;
					font-weight: 400;
					text-transform: uppercase;
					text-align: left;
					position: relative;
				`}
				${media.maxXs`font-size: 25px`}
    	}
    	${P} {
				color: #fff;
				padding: 74px 100px 110px;  	
    		font-family: ${fonts.openSans};
				font-size: 15px;
				line-height: 30px;
				${media.maxXl`
					padding-top: 20px;
    			padding-bottom: 60px
				`}
				${media.laptopS`display: none;`}
    	}
    	.subtitle {
    		display: block;
    		margin-top: 15px;
    		color: ${({ subTitleColor }) => subTitleColor};
    		font-family: ${fonts.openSansLightItalic};
				font-size: 15px;
				${media.laptopS`display: none;`}
    	}
    	.subtitle-mobile {
    		display: block;
    		color: ${colors.white};
				font-family: ${fonts.robotoLightItalic};
				font-size: 17px;
				${media.maxSm`font-size: 15px;`}
    	}
    	.mobile-wrapper {
				display: none;
				${media.laptopS`
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')}
					width: 100%; 
					background-color: ${({ mobileWrapperBg }) => mobileWrapperBg};
				`}
				${H3} {
					&::before {
						content: "";
						width: 100%;
						height: 1px;
						position: absolute;
						bottom: 10px;
						left: 0;
						display: block;
						background-color: ${colors.white};
}
					}
				}
				.desc-case {
					 padding: 20px;
					 text-align: left;
					 .link-wrapper {
					 		text-align: center;
					 		a {
					 			display: block;
    						margin: 25px 	0
					 		}
					 }
				}
				}
				.tags-case {
					display: none;
					${media.laptopS`
						display: flex;
						flex-wrap: wrap;
						padding-top: 33px;
						padding-left: 0;
						padding-bottom: 33px;
					`}
					li { 
							color: ${colors.whiteGrey};
							font-family: ${fonts.robotoLightItalic};
							font-size: 14px;
							padding: 7px 13px;
							border-radius: 50px;
							position: relative;
							display: flex;
							justify-content: center;
							align-items: center;
							margin: 8px;
							background-color: ${({ tagBgColor }) => tagBgColor};
							border: 1px solid ${({ tagBorderColor }) => tagBorderColor};
							&::before {  
								width: 5px;
								height: 5px;
								background: ${colors.white};
								border-radius: 50%;
								content: "";
								display: inline-block;
								margin-right: 10px;
							}
					 }
    	}
		.btn-wrapper {
			margin-bottom: 90px;
			a {
				background-color: ${({ btnBgColor }) => btnBgColor};
			}
			a:hover {
				color: ${colors.white} !important;
				border: 2px solid ${colors.white};
				background-color: transparent;
				&::after {
					background-position: -10px -10px;
				}
			}
	}
`;

const Project = ({
	children,
	title,
	subTitle,
	desc,
	img,
	imgMobile,
	link,
	mainBgColor,
	subTitleColor,
	linearGradient,
	mobileWrapperBg,
	tags,
	tagBgColor,
	tagBorderColor,
	reverse,
	noTitle,
	mobileLogoWidth,
	mobileLogoHeight,
	btnBgColor,
	altText,
	altTextMobile,
}) => (
	<StyledProject
		mainBgColor={mainBgColor}
		linearGradient={linearGradient}
		subTitleColor={subTitleColor}
		mobileWrapperBg={mobileWrapperBg}
		tagBgColor={tagBgColor}
		tagBorderColor={tagBorderColor}
		reverse={reverse}
		noTitle={noTitle}
		btnBgColor={btnBgColor}
	>
		<AnimatedOnScroll animateIn="fadeInRight" animateOut="fadeOutRight">
			<div className="left-col">
				<figure>
					<img src={img} alt={altText} />
				</figure>
				<H3>{title}</H3>
				<span className="subtitle">{subTitle}</span>
				<P>{desc}</P>
				<div className="mobile-wrapper">
					<div className="desc-case">
						<H3>{title}</H3>
						<span className="subtitle-mobile">{subTitle}</span>
						<div className="link-wrapper">
							<a href={link} target="_blank" rel="noopener noreferrer">
								<img src={linkArrow} width="23" height="11" alt="" />
							</a>
						</div>
					</div>
					<figure className="mobile-case-logo">
						<img
							src={imgMobile}
							width={mobileLogoWidth}
							height={mobileLogoHeight}
							alt={altTextMobile}
						/>
					</figure>
				</div>
				<ul className="tags-case">
					{tags.map((tag, index) => (
						<li key={index}>{tag}</li>
					))}
				</ul>
				<div className="btn-wrapper">
					<ProjectButton linkTo={link}>View case study</ProjectButton>
				</div>
			</div>
		</AnimatedOnScroll>
		<AnimatedOnScroll animateIn="fadeInLeft" animateOut="fadeOutLeft">
			{children}
		</AnimatedOnScroll>
	</StyledProject>
);

Project.propTypes = {
	children: PropTypes.object,
	title: PropTypes.string,
	subTitle: PropTypes.string,
	desc: PropTypes.string,
	img: PropTypes.string,
	imgMobile: PropTypes.string,
	link: PropTypes.string,
	mainBgColor: PropTypes.string,
	subTitleColor: PropTypes.string,
	linearGradient: PropTypes.string,
	mobileWrapperBg: PropTypes.string,
	tags: PropTypes.array,
	tagBgColor: PropTypes.string,
	tagBorderColor: PropTypes.string,
	reverse: PropTypes.bool,
	noTitle: PropTypes.bool,
	mobileLogoWidth: PropTypes.string,
	mobileLogoHeight: PropTypes.string,
	btnBgColor: PropTypes.string,
	altText: PropTypes.string,
	altTextMobile: PropTypes.string,
};

Project.defaulProps = {
	reverse: false,
	noTitle: false,
};

export default Project;
