import React from 'react';
import ContentSection from '../../ContentSection';
import Project from '../Projects/Project';
import RambleChat from './RambleChat';
import SelfDecode from './SelfDecode';
import OpenDrive from './OpenDrive';
import Nutrino from './Nutrino';
import FurniturePlace from './FurniturePlace';
import rambleLogo from '../../../assets/img/ramble-logo-alt.png';
import rambleMobileLogo from '../../../assets/img/ramble-mobile-alt.png';
import selfDecodeLogo from '../../../assets/img/selfdecode-logo.png';
import selfDecodeMobileLogo from '../../../assets/img/self-case2.png';
import openDriveLogo from '../../../assets/img/opendrive-logo.png';
import openDriveMobileLogo from '../../../assets/img/opendrive.png';
import nutrinoLogo from '../../../assets/img/nutrino-logo.png';
import nutrinoMobileLogo from '../../../assets/img/nutrino-case.png';
import furniturePlaceLogo from '../../../assets/img/ar-logo.png';
import furniturePlaceMobileLogo from '../../../assets/img/ar-logo-1.png';

const tags = ['Phyton 3', 'Django REST', 'PgSQL/pgpool-II'];

const Projects = () => (
	<ContentSection
		title="Projects That Make Us Proud"
		subtitle="Engage your customers with a fully customized web and mobile app"
		fluid
	>
		<Project
			title="Ramble Chat"
			subTitle="… a chat app that works everywhere and on every device"
			desc="Not all chat programs are created equal.
			Ramble Chat is the universal solution for individuals and businesses that need to chat across any platform.
			This free and scalable app works on any device – PC, Mac, iPhone, or Android.
			It allows real-time messaging for visitors to a website, blog, social media page, e-commerce site, or banner ad.
			It presents an ideal marketing solution for businesses because it helps to capture and generate leads from new avenues on the internet.
			Best of all, it is instant! No waiting for texts, email responses, or phone calls."
			img={rambleLogo}
			imgMobile={rambleMobileLogo}
			link="https://cases.pixelteh.com/ramble/"
			mainBgColor="#573590"
			subTitleColor="#ce4cee"
			linearGradient="to right, #300e4f, #3a185f, #45226f, #4f2c80, #593692"
			mobileWrapperBg="rgba(51, 17, 85, 0.46)"
			tags={tags}
			tagBgColor="#351357"
			tagBorderColor="#6834a9"
			mobileLogoWidth="142"
			mobileLogoHeight="144"
			btnBgColor="#744897"
			altText={'purple ramble chat icon'}
			altTextMobile={'dark selfdecode icon'}
		>
			<RambleChat />
		</Project>

		<Project
			title="SelfDecode"
			subTitle="… decodify your genome for a healthier life"
			desc="How would you like to have personalized and detailed information and reports based on your DNA?
			It sounds like science fiction, but it isn’t. After uploading genetic data,
			a subscriber discovers how their genes affect their health, such as minor and major medical conditions.
			The program gives you the most scientifically-accurate, up-to-date information so that you can make the best decisions for your health.
			Subscribers of the program uncover their genetic weaknesses and play to their strengths.
			After obtaining all the pertinent information from you, SelfDecode integrates genetics, blood tests,
			symptoms, and more to give you the best recommendations possible."
			img={selfDecodeLogo}
			imgMobile={selfDecodeMobileLogo}
			link="https://cases.pixelteh.com/selfdecode/"
			mainBgColor="#00a5cb"
			subTitleColor="#00678c"
			linearGradient="to right, #3db3be, #3db3be, #3db3be, #3db3be, #3db3be"
			mobileWrapperBg="rgba(47, 170, 182, 0.7)"
			tags={tags}
			tagBgColor="#13a19f"
			tagBorderColor="#4bc4c1"
			reverse={true}
			mobileLogoWidth="94"
			mobileLogoHeight="98"
			btnBgColor="#0190b0"
			altText={'blue selfdecode icon'}
			altTextMobile={'grey selfdecode icon'}
		>
			<SelfDecode />
		</Project>

		<Project
			title="Opendrive"
			subTitle="… all-in-one cloud storage"
			desc="In a world where everything is on the Cloud, people need content management that is secure, powerful, and accessible from anywhere.
			OpenDrive is a total Cloud backup and storage solution for businesses of all kinds and sizes.
			It’s available on all platforms – the internet, desktop, and mobile devices.
			This is a fully responsive design with interfaced customization options for partners and business accounts.
			It supports Windows, Mac, iOS, Android, Web, and WebDAV. Store, sync, or backup your documents, multi-media,
			and pictures online with instant access, sharing, and collaboration – all in a simple to use, secure environment."
			img={openDriveLogo}
			imgMobile={openDriveMobileLogo}
			link="https://cases.pixelteh.com/opendrive/"
			mainBgColor="#3d5a99"
			subTitleColor="#9ac2fa"
			linearGradient="to right, #204078, #22447f, #244786, #274b8d, #294f94"
			mobileWrapperBg="rgba(31, 62, 118, 0.6)"
			tags={tags}
			tagBgColor="rgba(36, 69, 130, 0.58)"
			tagBorderColor="#2c5aaa"
			noTitle={true}
			mobileLogoWidth="100"
			mobileLogoHeight="80"
			btnBgColor="#567ec0"
			altText={'opendrive logo'}
			altTextMobile={'opendrive lines'}
		>
			<OpenDrive />
		</Project>

		<Project
			title="Nutrino"
			subTitle="… a better way to eat well and stay fit"
			desc="Wouldn’t you like your own personal chef? Even though the Nutrino app doesn’t prepare your meals,
			it does provide personalized meal recommendations along with continuous and comprehensive nutritional support.
			Using AI programming developed by PixelTeh, the program learns about you the more you interact with it.
			It incorporates state-of-the-art science, natural language processing, and mathematical models for predictive analysis.
			That means you receive tailored food recommendations based on everything about you.
			In addition, this advanced app can be fully integrated with many devices and services,
			such as Fitbit, Runkeeper, 23andMe, and Garmin."
			img={nutrinoLogo}
			imgMobile={nutrinoMobileLogo}
			link="https://cases.pixelteh.com/nutrino-2/"
			mainBgColor="#77c277"
			subTitleColor="#086d08"
			linearGradient="none"
			mobileWrapperBg="rgba(140, 200, 130, 0.6)"
			tags={tags}
			tagBgColor="rgba(137, 216, 124, 0.6)"
			tagBorderColor="#8ff67e"
			reverse={true}
			noTitle={true}
			mobileLogoWidth="120"
			mobileLogoHeight="120"
			btnBgColor="#5eb15e"
			altText={'nutrino logo'}
			altTextMobile={'nutrino image leaf logo'}
		>
			<Nutrino />
		</Project>

		<Project
			title="FurniturePlace"
			subTitle="… a virtual way to shop and try before you buy"
			desc="With the FurniturePlace app, you don’t need to browse multiple stores to find your furniture – only to bring home
			and discover it doesn’t work with your home décor. The app we created is an effective and impressive tool for any company
			to market their brand – whether it’s furniture, cars, jewelry, or other products.
			Using Augmented Reality (AR), PixelTeh developed an app that allows customers to try before they buy.
			Consumers simply select a furniture product and use their mobile phones or tablets to place it virtually in any room.
			We also integrated the app for easy messaging through texts, emails, and social media."
			img={furniturePlaceLogo}
			imgMobile={furniturePlaceMobileLogo}
			link="https://cases.pixelteh.com/furniture/"
			mainBgColor="#141e30"
			subTitleColor="#556b93"
			linearGradient="none"
			mobileWrapperBg="rgba(27, 36, 53, 0.7)"
			tags={tags}
			tagBgColor="rgba(19, 28, 45, 0.6)"
			tagBorderColor="#253859"
			mobileLogoWidth="100"
			mobileLogoHeight="72"
			btnBgColor="#293345"
			altText={'red AR for augmented reality'}
			altTextMobile={'red AR for augmented reality'}
		>
			<FurniturePlace />
		</Project>
	</ContentSection>
);

export default Projects;
