import PropTypes from 'prop-types';
import styled from 'styled-components';
import commonButtonStyles from '../../../_common/commonStyles/commonButtonStyles';

const StyledProjectLink = styled.a.attrs({
	target: '_blank',
	rel: 'noopener noreferrer',
})`
	${commonButtonStyles}
`;

StyledProjectLink.propTypes = {
	arrow: PropTypes.bool,
};

export default StyledProjectLink;
