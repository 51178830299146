import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import { media, colors, fonts } from '../../../globalStyles';
import blockchainIcon from '../../../assets/img/blockchain-sprite.png';
import blockchainMobileIcon from '../../../assets/img/blockchain-mobile.png';
import webIcon from '../../../assets/img/web-sprite.png';
import webMobileIcon from '../../../assets/img/web-app-xs.png';
import aiIcon from '../../../assets/img/sprite3.png';
import aiMobileIcon from '../../../assets/img/machine-xs.png';
import phoneIcon from '../../../assets/img/mobsprite.png';
import phoneMobileIcon from '../../../assets/img/mobile-app-xs.png';
import redRound from '../../../assets/img/red-round.png';
import dottedLine from '../../../assets/img/dotted-line.png';

const StyledPlatforms = styled.div`
	display: flex;
	padding-top: 60px;
	${media.maxSm`
		flex-direction: column;
		align-items: center;
	`}
	.platform {
		text-align: center;
		height: 277px;
		width: 25%;
		${media.maxSm`
    	width: auto;
    	height: auto;
    `}
		&:not(:last-of-type) {
			border-right: 1px solid #c9c9c9;
			${media.maxMd`border-right: none;`}
		}
		&:hover {
			${media.minSm`
				border-color: transparent;
				transition: background-color 0.5s ease-out;
				background-color: ${colors.blueDark};
				.platform-name {
					color: ${colors.white};
				}
			`}
		}
		&:nth-last-of-type(2n) .platform-name {
			left: 85%;
			${media.maxXs`left: 48%;`}
		}
		&:nth-of-type(1):hover .icon-platform {
			background-position: -147px -10px;
		}
		&:nth-of-type(2):hover .icon-platform {
			background-position: -150px -10px;
		}
		&:nth-of-type(3):hover .icon-platform {
			background-position: 0px -10px;
		}
		&:nth-of-type(4):hover .icon-platform {
			background-position: -112px -10px;
		}
		a {
			display: block;
			width: 100%;
			height: 100%;
			padding: 40px 18px 40px 19px;
			text-decoration: none;
			${media.maxSm`padding: 0;`}
		}
		&:nth-of-type(1) {
			.icon-platform {
				background-position: -10px -10px;
			}
			.hidden-sm {
				background-image: url(${blockchainIcon});
			}
			.visible-sm {
				background-image: url(${blockchainMobileIcon});
			}
		}
		&:nth-of-type(2) {
			.icon-platform {
				background-position: -10px -10px;
			}
			.hidden-sm {
				background-image: url(${webIcon});
			}
			.visible-sm {
				background-image: url(${webMobileIcon});
			}
		}
		&:nth-of-type(3) {
			.icon-platform {
				background-position: -120px -10px;
			}
			.hidden-sm {
				background-image: url(${aiIcon});
			}
			.visible-sm {
				background-image: url(${aiMobileIcon});
			}
		}
		&:nth-of-type(4) {
			.icon-platform {
				background-position: 6px -10px;
			}
			.hidden-sm {
				background-image: url(${phoneIcon});
			}
			.visible-sm {
				background-image: url(${phoneMobileIcon});
			}
		}
		.icon-platform {
			width: 120px;
			height: 130px;
			display: block;
			margin: 0 auto;
			background-repeat: no-repeat;
			${media.maxSm`
				width: 163px;
				height: 163px;
				margin-bottom: 5px;
				margin-top: 5px;
				background-position: 0px 0px !important;
			`}
		}
		.name-wrap {
			${media.maxSm`
				padding: 50px 0;
				position: relative;
				width: 100%;
				display: flex;
				background: url(${redRound}) no-repeat center center;
			`}
			&:before,
			&:after {
				${media.maxSm`
					content: '';
					display: block;
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					-webkit-transform: translateX(-50%);
					width: 1px;
					height: 50px;
					background: url(${dottedLine}) repeat-y;
				`}
			}
			&:before {
				${media.maxSm`top: 0;`}
			}
			&:after {
				${media.maxSm`bottom: 0;`}
			}
		}
		.platform-name {
			color: ${colors.greyItalicTitle};
			font-family: ${fonts.robotoLightItalic};
			font-size: 17px;
			line-height: 24px;
			${media.maxSm`
				position: absolute;
				bottom: 40px;
				left: -85%;
				width: 80%;
			`}
			${media.maxXs`left: -48%;`}
		}
	}
`;

const Platforms = () => (
	<ContentSection
		title="Pick Up Your Platform"
		subtitle="We develop software for a variety of platforms. Our solutions are perfectly tailored to your business needs."
		fluid
		seoTitle={'h2'}
		seoSubTitle={'h3'}
	>
		<StyledPlatforms>
			<div className="platform">
				<Link to="/technologies-blockchain">
					<i className="icon-platform hidden-sm" />
					<i className="icon-platform visible-sm" />
					<div className="name-wrap">
						<span className="platform-name">
							Blockchain & Cryptocurrency Programming
						</span>
					</div>
				</Link>
			</div>
			<div className="platform">
				<Link to="/technologies-full-stack">
					<i className="icon-platform hidden-sm" />
					<i className="icon-platform visible-sm" />
					<div className="name-wrap">
						<span className="platform-name">Web Application Programming</span>
					</div>
				</Link>
			</div>
			<div className="platform">
				<Link to="/technologies-machine-learning">
					<i className="icon-platform hidden-sm" />
					<i className="icon-platform visible-sm" />
					<div className="name-wrap">
						<span className="platform-name">
							Artificial Intelligence & Machine Learning
						</span>
					</div>
				</Link>
			</div>
			<div className="platform">
				<Link to="/technologies-ios">
					<i className="icon-platform hidden-sm" />
					<i className="icon-platform visible-sm" />
					<div className="name-wrap">
						<span className="platform-name">
							Mobile Application Programming
						</span>
					</div>
				</Link>
			</div>
		</StyledPlatforms>
	</ContentSection>
);

export default Platforms;
