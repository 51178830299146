import React from 'react';
import styled from 'styled-components';
import { media, colors, fonts } from '../../../globalStyles';
import P from '../../_common/P';
import blockBgImg from '../../../assets/img/bg-case5.png';
import pinappleImg from '../../../assets/img/nutrino-home-pinapple.png';

const StyledNutrino = styled.div`
	height: 100%;
	background: #fff url(${blockBgImg}) no-repeat 0 100%;
	padding: 116px 0 180px;
	figure {
		text-align: center;
	}
	hr {
		width: 246px;
		height: 2px;
		background: #77c277;
		margin-top: 44px;
		margin-bottom: 28px;
		border: none;
	}
	${P} {
		font-size: 38px;
		line-height: 56px;
		color: ${colors.blackTitle};
		font-family: ${fonts.openSans};
		text-align: center;
		${media.maxXl`
    		font-size: 28px;
    		line-height: 35px;
    	`}
		span {
			font-family: ${fonts.openSansBold};
			margin-left: 5px;
		}
	}
`;

const Nutrino = () => (
	<StyledNutrino>
		<figure>
			<img src={pinappleImg} width="322" height="298" alt="sliced pineapple" />
		</figure>
		<hr />
		<P>
			YOU LOVE FOOD,
			<br />
			FIND OUT
			<span>
				WHICH FOODS
				<br />
				LOVE YOU BACK
			</span>
		</P>
	</StyledNutrino>
);

export default Nutrino;
