import React from 'react';
import styled from 'styled-components';
import { media } from '../../../globalStyles';
import logo from '../../../assets/img/logo-360.png';
import blockBg from '../../../assets/img/360-bg.png';
import dottedLine from '../../../assets/img/dotted-line2.png';
import arImg from '../../../assets/img/ar-img-2.png';

const StyledFurniturePlace = styled.div`
	height: 100%;
	padding-top: 58px;
	background: url(${blockBg}) no-repeat 0 0 / cover;
	.logo {
		text-align: center;
	}
	.dotted-line {
		width: 3px;
		height: 100%;
		max-height: 478px;
		margin: 0 auto;
		background: url(${dottedLine}) repeat-y;
	}
	.ar-img {
		position: relative;
		top: -15%;
		left: -2.5%;
		max-width: 432px;
		max-height: 220px;
		margin: 0 auto;
		${media.maxXl`
			top: -26%;
			left: -3%;
    `}
		img {
			width: 100%;
			height: 100%;
		}
	}
`;

const FurniturePlace = () => (
	<StyledFurniturePlace>
		<figure className="logo">
			<img
				src={logo}
				width="145"
				height="146"
				alt="orange circle with 360 degrees"
			/>
		</figure>
		<div className="dotted-line" />
		<figure className="ar-img">
			<img src={arImg} width="555" height="279" alt="vr and ar glasses" />
		</figure>
	</StyledFurniturePlace>
);

export default FurniturePlace;
