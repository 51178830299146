import React from 'react';
import styled from 'styled-components';
import { media, colors, fonts } from '../../../globalStyles';
import H1 from '../../_common/H1';
import H4 from '../../_common/H4';
import P from '../../_common/P';
import cloudIcon from '../../../assets/img/cloud-upload.svg';
import serverIcon from '../../../assets/img/server.svg';
import shareIcon from '../../../assets/img/share.svg';
import phoneIcon from '../../../assets/img/phone.svg';
import headerBgImg from '../../../assets/img/open-drive-bg.png';

const StyledOpenDrive = styled.div`
	height: 100%;
	background-color: ${colors.white};
	.op-header {
		padding-top: 50px;
		font-family: ${fonts.robotoRegular};
		color: ${colors.white};
		background: url(${headerBgImg}) no-repeat center top #365277;
		${H1} {
			font-size: 44px;
			text-align: center;
		}
		${H4} {
			text-align: center;
			font-size: 20px;
			margin-top: 10px;
			padding-bottom: 50px;
		}
	}
	.op-content {
		display: flex;
		flex-wrap: wrap;
		&-item {
			width: 45.5%;
			background-color: #f6f6f6;
			padding: 50px 20px 20px 20px;
			text-align: center;
			margin-top: 20px;
			position: relative;
			${media.maxXl`50px 10px 20px;`}
			&:nth-child(2n + 1) {
				margin-left: 3%;
				margin-right: 1.5%;
			}
			&:nth-child(2n) {
				margin-left: 1.5%;
				margin-right: 3%;
			}
			.icon-container {
				position: absolute;
				top: -40px;
				left: calc(50% - 40px);
				display: flex;
				align-items: center;
				justify-content: center;
				width: 80px;
				height: 80px;
				border-radius: 50%;
				background-color: ${colors.white};
				img {
					width: 60%;
				}
			}
			${H4} {
				margin-top: 10px;
				margin-bottom: 10px;
				font-size: 18px;
				font-family: ${fonts.openSans};
			}
			${P} {
				color: ${colors.blackTitle};
				font-family: ${fonts.openSans};
				font-size: 14px;
				line-height: 30px;
				text-align: center;
			}
		}
	}
`;

const OpenDrive = () => (
	<StyledOpenDrive>
		<div className="op-header">
			<H1>All-in-One Cloud Storage</H1>
			<H4>
				Unlimited Cloud Storage, Backup & Cloud
				<br />
				Content Management
			</H4>
		</div>
		<div className="op-content">
			<div className="op-content-item">
				<div className="icon-container">
					<img src={cloudIcon} alt="blue cloud with arrow pointing up" />
				</div>
				<H4>Online Storage</H4>
				<P>
					Store your documents, music and pictures online with instant access,
					sharing and collaboration, all in a simple to use, secure environment.
				</P>
			</div>
			<div className="op-content-item">
				<div className="icon-container">
					{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
					<img src={serverIcon} alt="blue bulleted list image" />
				</div>
				<H4>Online Backup</H4>
				<P>
					Back up all of your data online, completely secured in our
					state-of-the-art data centers and accessible whenever and wherever you
					need it.
				</P>
			</div>
			<div className="op-content-item">
				<div className="icon-container">
					<img src={shareIcon} alt="three blue connected dots" />
				</div>
				<H4>Online File Sharing</H4>
				<P>
					Share files and folders with anyone or add users to your OpenDrive
					account to access them directly. Stop being restricted by email
					attachment sizes – share files simply with OpenDrive.
				</P>
			</div>
			<div className="op-content-item">
				<div className="icon-container">
					<img src={phoneIcon} alt="blue phone icon" />
				</div>
				<H4>Access from Anywhere</H4>
				<P>
					Stay connected with your PC, Mac, iPhone, iPad, Android, internet
					browser or API-enabled device. The cloud is always at your fingertips
					with our full suite of apps that cover a wide range of platforms.
				</P>
			</div>
		</div>
	</StyledOpenDrive>
);

export default OpenDrive;
