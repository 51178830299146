import React from 'react';
import styled from 'styled-components';
import { media, colors, fonts } from '../../../globalStyles';
import cloudsImg from '../../../assets/img/clouds.png';

const StyledRambleChat = styled.div`
	height: 100%;
	padding: 95px 103px 50px 56px;
	background-color: #925c9f;
	${media.maxXxl`padding: 20px;`};
	.ra-chat {
		max-width: 600px;
		margin: 0 auto;
		padding-bottom: 240px;
		background: #925c9f url(${cloudsImg}) no-repeat 14% 92%;
		background-size: contain;
		&-comment-box {
			padding: 0 38px 0 45px;
			.comment-box {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 100%;
				padding: 10px;
				border: 1px solid ${colors.white};
				border-radius: 2px;
				min-height: 50px;
				overflow: hidden;
				span {
					font-size: 17px;
					color: ${colors.white};
					text-transform: uppercase;
				}
			}
		}
		&-messages {
			padding: 20px 38px 12px 45px;
		}
		&-message {
			width: 100%;
			border-radius: 3px;
			margin-bottom: 10px;
			position: relative;
			font-family: ${fonts.openSans};
			&.me {
				padding: 23px 30px 20px;
				font-size: 25px;
				line-height: 34px;
				color: ${colors.brownText};
				text-align: right;
				background-color: ${colors.white};
				${media.maxXl`
					font-size: 18px;
					line-height: 27px;
    		`}
				&::after {
					content: '';
					position: absolute;
					right: -18px;
					top: 9px;
					border: 8px solid transparent;
					border-left: 10px solid #fff;
				}
			}
			&.ramble {
				font-size: 17px;
				padding: 12px 30px 14px 30px;
				line-height: 26px;
				background-color: rgba(178, 140, 187, 0.95);
				color: ${colors.white};
				&::after {
					content: '';
					position: absolute;
					left: -18px;
					top: 9px;
					border: 8px solid transparent;
					border-right: 10px solid hsla(0, 0%, 100%, 0.3);
				}
			}
		}
	}
`;

const RambleChat = () => (
	<StyledRambleChat>
		<div className="ra-chat">
			<div className="ra-chat-messages">
				<div className="ra-chat-message me">Why all the excitement?</div>
				<div className="ra-chat-message ramble">
					It revolutionizes how we engage digitally with others.
				</div>
				<div className="ra-chat-message me">What’s the difference?</div>
				<div className="ra-chat-message ramble">
					Access the new chat platform from anywhere online.
				</div>
				<div className="ra-chat-message me">Does it work in real time?</div>
				<div className="ra-chat-message ramble">
					No lagging and no delays whatsoever. It’s simple, secure, and instant!
				</div>
			</div>
			<div className="ra-chat-comment-box">
				<div className="comment-box">
					<span>Send</span>
				</div>
			</div>
		</div>
	</StyledRambleChat>
);

export default RambleChat;
