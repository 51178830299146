import React from 'react';
import PropTypes from 'prop-types';
import TextCenter from '../../../_common/TextCenter';
import StyledProjectLink from './ProjectLink';

const CenterButton = ({ linkTo }) => (
	<TextCenter>
		<StyledProjectLink href={linkTo} arrow>
			View Case Study
		</StyledProjectLink>
	</TextCenter>
);
CenterButton.propTypes = {
	linkTo: PropTypes.string,
};

export default CenterButton;
