import React from 'react';
import styled from 'styled-components';
import { media, colors, fonts } from '../../../globalStyles';
import H2 from '../../../components/_common/H2';
import H3 from '../../../components/_common/H3';
import P from '../../../components/_common/P';
import geneticIcon from '../../../assets/img/genetic-icon.png';
import lockIcon from '../../../assets/img/lock-icon.png';
import heartIcon from '../../../assets/img/heart-icon.png';
import starIcon from '../../../assets/img/star-icon.png';

const StyledSelfDecode = styled.div`
	height: 100%;
	padding: 55px 65px 50px 35px;
	background-color: ${colors.white};
	${media.maxXxl`padding: 20px 5px;`};
	${H2} {
		font-size: 40px;
		font-family: ${fonts.openSans};
		color: ${colors.blackTitle};
		margin-bottom: 50px;
		text-align: center;
		span {
			font-size: 50px;
			font-family: ${fonts.openSansBold};
		}
	}
	span {
		margin-left: 5px;
	}
	.items-list {
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		.item {
			width: 50%;
			padding-right: 15px;
			padding-left: 15px;
			margin-bottom: 44px;
			.image {
				margin-bottom: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					height: 100px;
					width: 105px;
				}
			}
			${H3} {
				margin-bottom: 15px;
				font-size: 18px;
				font-family: ${fonts.robotoRegular};
				color: #4569fa;
				text-align: center;
				span {
					font-family: ${fonts.robotoBold};
				}
			}
			${P} {
				color: ${colors.blackTitle};
				font-family: ${fonts.openSans};
				font-size: 15px;
				line-height: 30px;
				text-align: center;
			}
		}
	}
`;

const SelfDecode = () => (
	<StyledSelfDecode>
		<H2>
			How it<span>works</span>
		</H2>
		<ul className="items-list">
			<li className="item">
				<div className="image">
					<img src={geneticIcon} alt="genetic data strand" />
				</div>
				<H3>
					Upload your<span>genetic data</span>
				</H3>
				<P>
					We accept all major genotyping services (e.g. 23andMe, Ancestry,
					FamilyTree etc.)
				</P>
			</li>

			<li className="item">
				<div className="image">
					<img src={lockIcon} alt="open lock and key" />
				</div>
				<H3>
					Unlock your<span>Genetics</span>
				</H3>
				<P>
					Unlock your genetics and discover valuable information about your
					genome that will help improve your lifestyle
				</P>
			</li>

			<li className="item">
				<div className="image">
					<img src={heartIcon} alt="heart with magnifying glass" />
				</div>
				<H3>
					Enter your<span>symptoms</span>
				</H3>
				<P>
					This data informs the genetic pathways that we will recommend you
					focus on improving
				</P>
			</li>
			<li className="item">
				<div className="image">
					<img src={starIcon} alt="star with pill capsule" />
				</div>
				<H3>
					Get<span>recommendations</span>
				</H3>
				<P>
					We integrate genetics, blood tests, symptoms and more, to give you the
					best recommendations
				</P>
			</li>
		</ul>
	</StyledSelfDecode>
);

export default SelfDecode;
